import React from 'react';
import { estimate } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import { getLinItemJsx, sumupDiscounts } from '../../util/PurchaseSummaryUtil';
import { Redirect, withRouter } from 'react-router';
import PropTypes from "prop-types";
import { isSuccess } from '../../constants/Status';

class PurchaseSummary extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            coupon: null,
            couponDb: null,
            discount: '$0.00',
            agreeTerms: false,
            isLoading: true,
            estimateResponse: null,
            discount: 0.00,
            amountDue: 0.00
        }
        this.confirmElement = React.createRef();
        const accData = this.props.accountManagementData();
        const selectedPlan = this.props.location.data ? this.props.location.data.selectedPlan : null;
        this.isValidState = accData && (accData.selectedPlan || accData.selectedSeats || selectedPlan);
    }


    getEstimate = async () => {
        const cacheData = this.props.location.data;
        const auth = this.context;
        const accountManagementData = this.props.accountManagementData();
        let { uiAccountSubscriptionInfo, selectedPlan } = accountManagementData;
        if (!selectedPlan) {
            selectedPlan = cacheData.selectedPlan;
            accountManagementData.selectedPlan = selectedPlan;
        }
        accountManagementData.coupon = this.state.coupon;

        const noOfSeats = (accountManagementData.selectedSeats !== null && accountManagementData.selectedSeats !== undefined) ? accountManagementData.selectedSeats : uiAccountSubscriptionInfo.noOfSeats;
        const subscriptionDetails = new SubscriptionDetails(auth.user, selectedPlan, null, true, noOfSeats, null, this.state.coupon, false);
        await this.getEstimateResponse(auth, subscriptionDetails);


    }

    getDialog = () => {
        return (this.confirmElement.current);
    }
    componentDidMount = () => {
        if (this.isValidState && this.props.accountManagementData())
            this.getEstimate();
    }

    getEstimateResponse = (auth, subscriptionDetails) => {
        const response = estimate(auth, subscriptionDetails).then((response) => {
            if (isSuccess(response.status)) {
                const { discountLineItems, amountDue } = response.data;
                const discount = sumupDiscounts(discountLineItems);
                this.setState({
                    estimateResponse: response,
                    isLoading: false,
                    couponDb: this.state.coupon,
                    discount: discount,
                    amountDue: amountDue
                });
                const accountManagementData = this.props.accountManagementData();
                accountManagementData.amountDue = amountDue;
                this.props.setAccountManagementData({ ...accountManagementData })
            } else {
                this.setState({ isLoading: false }, () => {
                    if (this.state.coupon) {
                        this.getDialog().status('Please verify if you have entered a valid coupon code.')
                    } else
                        this.getDialog().status(response.data.message);
                });
            }

        }).catch(e => {
            this.setState({ isLoading: false }, () => {
                this.getDialog().status(e.message);
            });
        });

    }
    removeCoupon = () => {
        this.setState({
            coupon: null,
            couponDb: null,
            isLoading: true
        }, () => {
            this.getEstimate();
        });


    }

    readCoupon = () => {
        this.setState({ isLoading: true });
        this.getEstimate();
    }

    goBack = () => {
        this.props.history.goBack();
    }
    proceed = () => {

        if (this.state.agreeTerms)
            this.props.history.push(`${this.props.accPath}/billingDetails`);
        else {
            const confirm = this.confirmElement.current;
            confirm.status('You must agree to terms and conditions.');
        }
    }

    getCouponRowContent = () => {

        if (this.state.couponDb) {
            return (
                <div id="applied-coupons-div" >
                    <label id="discount-code-label">Discount code</label>
                    <span className="label label-primary border-black" style={{ marginRight: '8px', marginLeft: '4px' }} >{this.state.couponDb}</span>
                    <button id="remove-coupon" type="button" onClick={() => this.removeCoupon()} className="btn btn-sm btn-primary">Remove</button>
                </div>
            );

        } else {
            return (
                <div id="apply-coupon-div">
                    <form className="form-inline">
                        <label id="discount-code-label">Discount code</label>
                        <input type="text"
                            id="coupon"
                            className="form-control form-control-sm"
                            style={{ marginLeft: '9px' }}
                            autoComplete="off" value={this.state.coupon}
                            onChange={(e) => { this.setState({ coupon: e.target.value }) }} />
                        <button type="button"
                            id="apply-coupon"
                            onClick={() => { this.readCoupon() }}
                            style={{ marginLeft: '9px', fontFamily: 'calibri', fontSize: '13px', height: '25px', paddingTop: '2px' }}
                            className="btn btn-sm btn-primary">Apply
                                        </button>
                    </form>
                </div>
            );
        }
    }

    render() {
        if (this.isValidState)
            return waitForLoad(this.state.isLoading, this.renderFn);
        else
            return <Redirect to={`${this.props.accPath}/manageSubscription`} />
    }

    renderFn = () => {

        let { totalAmount, amountDue, credits, invoiceLineItems, discountLineItems, subTotalAmount } = this.state.estimateResponse.data;
        //TODO is it OK to do these operations in render?
        const planLine = getLinItemJsx(invoiceLineItems, 'PLAN', 'USD');
        const addonLine = getLinItemJsx(invoiceLineItems, 'ADDON', 'USD');


        return (
            <React.Fragment>
                <PanelTitle panelName="Purchase summary" />
                <div id="purchaseSummaryDiv">
                    <table id="purchaseSummaryTable">
                        <thead>
                            <tr style={{ borderBottom: '1px solid lightgray' }}>
                                <th style={{ paddingRight: 15 }}>Selected plan</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planLine}
                            {addonLine}
                            <tr className="separator-row">
                                <td colSpan={2} />
                            </tr>

                            <tr>
                                <td>Total</td>
                                <td>{`$${subTotalAmount.toFixed(2)}`}</td>
                            </tr>
                            <tr>
                                <td>Credit</td>
                                <td>{`$${credits.toFixed(2)}`}</td>
                            </tr>
                            <tr id="discountRow" className="summation-row" >
                                <td style={{ textAlign: 'right', paddingRight: '18px' }}>
                                    {this.getCouponRowContent()}
                                </td>
                                <td id="discounts">{`$${this.state.discount}`}</td>
                            </tr>
                            <tr>
                                <td>Amount due</td>
                                <td>{`$${amountDue.toFixed(2)}`}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div id="sq-agreement-div" style={{ borderTop: '1px solid', width: '98%', paddingTop: '2px' }} className="mt-3">
                        <label style={{ fontWeight: 'normal', fontFamily: 'calibri' }}><input type="checkbox" id="agree-terms" checked={this.state.agreeTerms ? 'checked' : null} onChange={(e) => { this.setState({ agreeTerms: e.target.value }) }} /> By
    checking this box, you agree to Seqnc Inc.'s <a href={process.env.PUBLIC_URL + '/policy/ChatCFO-Inc.-Terms-of-Use-Effective-30Dec24.pdf'} target="_blank">Terms of Service</a> and <a href={process.env.PUBLIC_URL + '/policy/ChatCFO-Inc.-Privacy Policy-Effective-30Dec24.pdf'} target="_blank">Privacy Policy</a></label>
                    </div>
                    <div id="navgationBtnsDiv"><button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={this.goBack}>Back</button>
                        <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={this.proceed}>Next:Payment details</button>
                    </div>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment >
        );
    }
}

PurchaseSummary.contextType = AuthContext;
export default withRouter(PurchaseSummary);
