import React from 'react';

const Footer = (props) => {
    return (
        <div className="footer">
            <p>
                <label style={{ marginRight: '51px', fontFamily: 'calibri', fontSize: '11px', fontWeight: 'normal', color: '#000' }}>© 2022
                    Seqnc Inc. All rights reserved.</label> <a href={process.env.PUBLIC_URL + '/policy/ChatCFO-Inc.-Terms-of-Use-Effective-30Dec24.pdf'} target="_blank" style={{ color: '#000', fontFamily: 'calibri', fontSize: '11px', fontWeight: 'normal' }}>
                    Terms of Service </a> &nbsp; &nbsp; <a href={process.env.PUBLIC_URL + '/policy/ChatCFO-Inc.-Privacy Policy-Effective-30Dec24.pdf'} target="_blank" style={{ fontFamily: 'calibri', color: '#000', fontSize: '11px', fontWeight: 'normal' }}>
                    Privacy policy </a> <label style={{ color: 'black', fontFamily: 'calibri', fontWeight: 'normal', fontSize: '11px' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp; support@formulasuite.com</label>
            </p>
        </div>

    );
}

export default Footer;